<template>
    <div class="question-screen">
        <SurveyPagination :count="questionCount" :current="questionIndex" />

        <SurveyQuestion v-model="answer" :question="currentQuestion" :higlight-correct="isSubmitted" />

        <div class="question-screen__footer">
            <h4 v-if="isSubmitted && questionIndex !== 3 && questionIndex !== 4" class="question-screen__post-text">
                Watch the video on the big screen
            </h4>

            <h4 v-else-if="isSubmitted && questionIndex === 3" class="question-screen__post-text">
                Watch how your peers replied on the big screen
            </h4>

            <h4 v-else-if="isSubmitted && questionIndex === 4" class="question-screen__post-text">
                See figures on the big screen for further information
            </h4>
            
            <div class="question-screen__actions">
                <button v-if="isTouched && !isSubmitted" class="button question-screen__submit-button" @click="submitAnswer">
                    Submit
                </button>
    
                <button v-if="showNextBtn && questionIndex !== 3 && questionIndex !== 4" class="button question-screen__restart-video-button" @click="restartVideo">
                    <img
                        width="20"
                        height="20"
                        src="@/assets/images/refresh-white.svg"
                        alt="refresh white icon"
                    />
                    <span style="margin-left: 0.5rem;">Restart Video</span>
                </button>
    
                <button v-if="showNextBtn" class="button question-screen__next-button" @click="goToNextQuestion">
                    <span style="margin-right: 0.5rem;">Next</span> <img
                        width="20"
                        height="20"
                        src="@/assets/images/arrow-right.svg"
                        alt="arrow right icon"
                    />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import SocketioService from '@/services/socketio';
import { QUESTION_TYPES, questions } from '@/questions';
import SurveyQuestion from '@/components/questions/SurveyQuestion.vue';
import SurveyPagination from '@/components/SurveyPagination.vue';
import AnalyticsService, { ANALYTICS_EVENT_TYPES } from '@/services/analytics';

export default {
    name: 'QuestionScreen',
    components: {
        SurveyQuestion,
        SurveyPagination
    },
    data() {
        return {
            isSubmitted: false,
            showNextBtn: false,
            answer: null
        };
    },
    computed: {
        isTouched() {
            switch(this.currentQuestion.type) {
            case QUESTION_TYPES.SINGLE_SELECT:
                return this.answer;
            case QUESTION_TYPES.MULTI_SELECT:
                return Array.isArray(this.answer) && this.answer.length;
            case QUESTION_TYPES.RANKED:
                return Array.isArray(this.answer) && this.answer.every((o) => o);
            default:
                return false;
            }
        },
        questionCount() {
            return questions.length;
        },
        questionIndex() {
            return parseInt(this.$route.params.questionIndex) || 0;
        },
        currentQuestion() {
            return questions[this.questionIndex];
        },
        isLastQuestion() {
            return this.questionIndex === questions.length - 1;
        }
    },
    watch: {
        questionIndex: {
            handler() {
                this.answer = null;
                this.isSubmitted = false;
                this.showNextBtn = false;
                SocketioService.openQuestion(this.questionIndex);
            },
            immediate: true
        }
    },
    methods: {
        submitAnswer() {
            const NEXT_BUTTON_SHOW_DELAY = 3000;

            AnalyticsService.trackEvent(ANALYTICS_EVENT_TYPES.QUESTION_RESPONSE, this.$route.path, {
                questionId: this.currentQuestion.id,
                questionTitle: this.currentQuestion.title,
                answer: Array.isArray(this.answer) ? this.answer.join(';') : this.answer,
                correct: this.answer === this.currentQuestion.answer,
            });

            const percentages = AnalyticsService.getResponsesPercentage(this.currentQuestion.id);

            SocketioService.submitQuestion(this.questionIndex, percentages);
            this.isSubmitted = true;

            // show next button with a delay
            setTimeout(() => {
                this.showNextBtn = true;
            }, NEXT_BUTTON_SHOW_DELAY)
        },
        goToNextQuestion() {
            this.answer = null;

            if (this.isLastQuestion) {
                this.$router.push({ name: 'SummaryScreen' });
                return;
            }

            this.$router.push({ path: `/question/${this.questionIndex + 1}` });
        },
        restartVideo() {
            SocketioService.restartVideo(this.questionIndex);
        }
    }
}
</script>