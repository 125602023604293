<template>
    <div class="start-screen">
        <VideoPlayer
            video-name="videos/background_loop_final_new.mp4"
            :show-video-timings="false"
            is-bg-video
            loop-video
        />

        <div class="start-screen__sidebar">
            <h1 class="start-screen__sidebar__title">
                Use the iPad to test your knowledge and learn about<br /> IgA nephropathy
            </h1>

            <button class="button start-screen__start-button" @click="startSurvey">
                Start
            </button>

            <span class="start-screen__veeva-code">MED-US-IgAN-2400042</span>
        </div>

        <img class="start-screen__calliditas-logo" src="@/assets/images/calliditas-logo.svg" alt="calliditas logo" />
    </div>
</template>

<script>
import SocketioService from '@/services/socketio';

export default {
    name: 'StartScreen',
    mounted() {
        SocketioService.start();
    },
    methods: {
        startSurvey() {
            this.$router.push({ path: `/question/${0}` });
        }
    }
};
</script>
