<template>
    <div class="summary-screen--receiver">
        <div class="summary-screen__body">
            <h1>Thank you for interacting with this experience</h1>

            <p>IgANexus is developed in collaboration with nephrology and immunology experts who are focused on advancing peer-to-peer scientific education in IgA nephropathy</p>

            <p>Visit the IgANexus website to subscribe for updates!</p>
        </div>
        
        <div class="qrcode-container">
            <div class="qrcode-icon-container">
                <img class="qrcode-icon" src="@/assets/images/phone-qr.svg" alt="phone qr icon" />
            </div>

            <span class="qrcode-pipe"></span>

            <div class="qrcode">
                <p class="qrcode-call-to-action-text">
                    Visit the IgANexus Website to learn more
                </p>
                <img class="qrcode-image" :src="require(`@/assets/images/qr_summary.png`)" alt="QR Code" />
            </div>
        </div>

        <img class="summary-screen__calliditas-logo" src="@/assets/images/calliditas-logo.svg" alt="calliditas logo" />

        <span class="summary-screen__veeva-code">MED-US-IgAN-2400042</span>
    </div>
</template>