import SocketioService from '@/services/socketio';
import store from '@/store';

// import { FirebaseAnalytics } from '@capacitor-firebase/analytics';

// FirebaseAnalytics.logEvent({
//   name: 'video_pause',
//   params: {
//       video_name: this.videoName
//   }
// });

export const ANALYTICS_EVENT_TYPES = {
    PAGEVIEW: 'pageview',
    MODAL_OPEN: 'modal_open',
    MODAL_CLOSE: 'modal_close',
    VIDEO_PLAY: 'video_play',
    VIDEO_PAUSE: 'video_pause',
    VIDEO_RESTART: 'video_restart',
    VIDEO_MODAL_OPEN: 'video_modal_open',
    VIDEO_MODAL_CLOSE: 'video_modal_close',
    QUESTION_RESPONSE: 'question_response',
    SURVEY_FEEDBACK: 'survey_feedback',
};

class AnalyticsService {
    _congressStorageData;
    numberOfSessions;
    sessionId;

    constructor() {
        this._congressStorageData = {};
        this.numberOfSessions = 0;
        this.sessionId = 'session_1'
    }

    get congressStorageData() {
        return this._congressStorageData;
    }

    set congressStorageData(newState) {
        this._congressStorageData = newState;
        localStorage.congressStorageData = JSON.stringify(newState);
    }

    
  
    init() {
        if (localStorage.congressStorageData) {
            this.congressStorageData = JSON.parse(
                localStorage.congressStorageData
            );

            this.numberOfSessions = Object.keys(
                this.congressStorageData
            ).length + 1;

            this.sessionId = 'session_' + this.numberOfSessions;
        }

    }

    addNewSession() {
        this.numberOfSessions += 1;
        this.sessionId = 'session_' + this.numberOfSessions;

        // Create a new session and events array
        this.congressStorageData = {
            ...this.congressStorageData,
            [this.sessionId]: {
                events: [],
                date: new Date().toISOString()
            }
        }
    }

    trackEvent(eventType, url, params = {}) {
        const updatedData = JSON.parse(JSON.stringify(this.congressStorageData));

        const event = {
            type: eventType,
            ...params,
            url: url,
            time: new Date().toISOString()
        }
        updatedData[this.sessionId].events.push(event);

        this.congressStorageData = updatedData;


        // log event to the server
        SocketioService.logEvent({
            roomId: store.getters['selectedRoom'],
            sessionId: this.sessionId,
            ...event
        });
    }

    getResponsesPercentage(questionId) {
        const todayDateString = new Date().toISOString().slice(0, 10);
        const todaySessions = Object.values(this.congressStorageData)
            .filter(session => session.date ? session.date.startsWith(todayDateString) : false);


        const questionResponses = todaySessions.flatMap(session => session.events)
            .filter(event => event.type === ANALYTICS_EVENT_TYPES.QUESTION_RESPONSE && event.questionId === questionId)
            .map(event => event.answer)

        // Count the occurrences of each answer
        const answerCounts = {};
        questionResponses.forEach(response => {
            var answers = response.split(';');
            answers.forEach(answer => {
                answerCounts[answer] = (answerCounts[answer] || 0) + 1;
            });
        });

        // Calculate the percentage for each answer
        const totalResponses = questionResponses.length;
        const answerPercentages = {};
        Object.keys(answerCounts).forEach(answer => {
            var count = answerCounts[answer];
            var percentage = (count / totalResponses) * 100;
            answerPercentages[answer] = Math.round(percentage.toFixed(2));
        });

        return answerPercentages;
    }
}

export default new AnalyticsService();