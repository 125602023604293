<template>
    <div class="start-screen">
        <VideoPlayer
            video-name="videos/background_loop_final_new.mp4"
            :show-video-timings="false"
            is-bg-video
            loop-video
        />

        <div class="start-screen__overlay">
            <img class="start-screen__iganexus-logo" src="@/assets/images/iganexus-logo.png" alt="iganexus logo" />

            <img class="start-screen__calliditas-logo" src="@/assets/images/calliditas-logo.svg" alt="calliditas logo" />

            <h1 class="start-screen__overlay__title">
                Use the iPad to test your knowledge and learn about<br /> IgA nephropathy
            </h1>

            <span class="start-screen__veeva-code start-screen__veeva-code--receiver">MED-US-IgAN-2400042</span>
        </div>
    </div>
</template>
